import React, { useState, useEffect } from "react";
import ReactEcharts from "echarts-for-react";
import { useAuth } from "../../context/auth";
import { Redirect } from "react-router-dom";
import {
  groupBy,
  useInterval,
  numberWithCommas,
  getSemiMonthFromMonth
} from "../../Utils";
import cloneDeep from "lodash.clonedeep";
import EnergyGraph from "./energyGraph";
import EnergyGraphSegment from "./energyGraphSegment";
import EnergyGraphLevel from "./energyGraphLevel";

import {
  energyLastHour,
  energyLastLastHour,
  todayConsumptionInfo,
  dailyEnergy
} from "../../api/energy";

function Energy(props) {
  const refreshInterval = 60000 * 10;

  const [chillerLastHour, setChillerLastHour] = useState(0);
  const [MVLastHour, setMVLastHour] = useState(0);
  const [liftLastHour, setLiftLastHour] = useState(0);
  const [PSLastHour, setPSLastHour] = useState(0);
  const [lightingLastHour, setLightingLastHour] = useState(0);
  const [tenantLastHour, setTenantLastHour] = useState(0);

  const [chillerLastLastHour, setChillerLastLastHour] = useState(0);
  const [MVLastLastHour, setMVLastLastHour] = useState(0);
  const [liftLastLastHour, setLiftLastLastHour] = useState(0);
  const [PSLastLastHour, setPSLastLastHour] = useState(0);
  const [lightingLastLastHour, setLightingLastLastHour] = useState(0);
  const [tenantLastLastHour, setTenantLastLastHour] = useState(0);

  const [chillerLastHourpm2, setChillerLastHourpm2] = useState(0);
  const [MVLastHourpm2, setMVLastHourpm2] = useState(0);
  const [liftLastHourpm2, setLiftLastHourpm2] = useState(0);
  const [PSLastHourpm2, setPSLastHourpm2] = useState(0);
  const [lightingLastHourpm2, setLightingLastHourpm2] = useState(0);
  const [tenantLastHourpm2, setTenantLastHourpm2] = useState(0);

  const [officeHour, setOfficeHour] = useState(0);
  const [nonOfficeHour, setNonOfficeHour] = useState(0);
  const [avg30Days, setAvg30Days] = useState(0);
  const [currentHour, setCurrentHour] = useState(0);
  const [grossFloorArea, setGrossFloorArea] = useState(48214);
  const [emissionsFactor, setEmissionsFactor] = useState(0);

  const [offPeakMin, setOffPeakMin] = useState(0);
  const [offPeakMax, setOffPeakMax] = useState(0);
  const [peakMin, setPeakMin] = useState(0);
  const [peakMax, setPeakMax] = useState(0);

  const [offPeakMinTime, setOffPeakMinTime] = useState("-");
  const [offPeakMaxTime, setOffPeakMaxTime] = useState("-");
  const [peakMinTime, setPeakMinTime] = useState("-");
  const [peakMaxTime, setPeakMaxTime] = useState("-");

  function getTotalEnergy(ret, mode) {
    for (var i = 0; i < ret.info.length; i++) {
      var obj = ret.info[i];

      if (obj.type === "MV") {
        var totalEnergy = 0;
        var dataArr = obj.data;
        for (var j = 0; j < dataArr.length; j++) {
          var data = dataArr[j];
          var multiplier = 1;

          totalEnergy =
            totalEnergy + (data.maxValue - data.minValue) * multiplier;
        }

        totalEnergy = Math.round(totalEnergy / 1);

        if (mode === 0) {
          setMVLastHour(totalEnergy);
          setMVLastHourpm2(((totalEnergy * 1) / grossFloorArea).toFixed(2));
        } else setMVLastLastHour(totalEnergy);
      }

      if (obj.type === "chiller") {
        var totalEnergy = 0;
        var dataArr = obj.data;
        for (var j = 0; j < dataArr.length; j++) {
          var data = dataArr[j];
          var multiplier = 1;

          totalEnergy =
            totalEnergy + (data.maxValue - data.minValue) * multiplier;
        }
        totalEnergy = Math.round(totalEnergy / 1);

        if (mode === 0) {
          setChillerLastHour(totalEnergy);
          setChillerLastHourpm2(
            ((totalEnergy * 1) / grossFloorArea).toFixed(2)
          );
        } else setChillerLastLastHour(totalEnergy);
      }

      if (obj.type === "lift") {
        var totalEnergy = 0;
        var dataArr = obj.data;
        for (var j = 0; j < dataArr.length; j++) {
          var data = dataArr[j];
          var multiplier = 1;

          totalEnergy =
            totalEnergy + (data.maxValue - data.minValue) * multiplier;
        }
        totalEnergy = Math.round(totalEnergy / 1);
        if (mode === 0) {
          setLiftLastHour(totalEnergy);
          setLiftLastHourpm2(((totalEnergy * 1) / grossFloorArea).toFixed(2));
        } else {
          setLiftLastLastHour(totalEnergy);
        }
      }

      if (obj.type === "PS") {
        var totalEnergy = 0;
        var dataArr = obj.data;
        for (var j = 0; j < dataArr.length; j++) {
          var data = dataArr[j];
          var multiplier = 1;

          totalEnergy =
            totalEnergy + (data.maxValue - data.minValue) * multiplier;
        }
        totalEnergy = Math.round(totalEnergy / 1);
        if (mode === 0) {
          setPSLastHour(totalEnergy);
          setPSLastHourpm2(((totalEnergy * 1) / grossFloorArea).toFixed(2));
        } else setPSLastLastHour(totalEnergy);
      }

      if (obj.type === "lighting") {
        var totalEnergy = 0;
        var dataArr = obj.data;
        for (var j = 0; j < dataArr.length; j++) {
          var data = dataArr[j];
          var multiplier = 1;

          totalEnergy =
            totalEnergy + (data.maxValue - data.minValue) * multiplier;
        }
        totalEnergy = Math.round(totalEnergy / 1);
        if (mode === 0) {
          setLightingLastHour(totalEnergy);
          setLightingLastHourpm2(
            ((totalEnergy * 1) / grossFloorArea).toFixed(2)
          );
        } else setLightingLastLastHour(totalEnergy);
      }

      if (obj.type === "tenant") {
        var totalEnergy = 0;
        var dataArr = obj.data;
        for (var j = 0; j < dataArr.length; j++) {
          var data = dataArr[j];
          var multiplier = 1;

          totalEnergy =
            totalEnergy + (data.maxValue - data.minValue) * multiplier;
        }
        totalEnergy = Math.round(totalEnergy / 1);
        if (mode === 0) {
          setTenantLastHour(totalEnergy);
          setTenantLastHourpm2(((totalEnergy * 1) / grossFloorArea).toFixed(2));
        } else setTenantLastLastHour(totalEnergy);
      }
    }
  }

  function energyLastHourCB(ret, index) {
    if (ret.return === 100) {
      getTotalEnergy(ret, 0);
    }
  }

  function energyLastLastHourCB(ret, index) {
    if (ret.return === 100) {
      getTotalEnergy(ret, 1);
    }
  }

  function todayConsumptionInfoCB(ret, index) {
    if (ret.return === 100) {
      if ("currentHour" in ret.info) {
        setCurrentHour(ret.info["currentHour"]);
      }
      if ("nonOfficeHour" in ret.info) {
        setNonOfficeHour(ret.info["nonOfficeHour"]);
      }
      if ("avg30Days" in ret.info) {
        setAvg30Days(ret.info["avg30Days"]);
      }
      if ("officeHour" in ret.info) {
        setOfficeHour(ret.info["officeHour"]);
      }
      
      if ("officeHour" in ret.info && "nonOfficeHour" in ret.info) {
        setEmissionsFactor((ret.info["officeHour"]+ret.info["nonOfficeHour"])*0.4657);
      }

    }
  }

  function dailyEnergyCB(ret, index) {
    if (ret.return === 100 && ret.info[0]) {
      if ("offpeak" in ret.info[0]) {
        setOffPeakMin(numberWithCommas(ret.info[0].offpeak.min.value,1));
        setOffPeakMinTime(ret.info[0].offpeak.min.ts);

        setOffPeakMax(numberWithCommas(ret.info[0].offpeak.max.value,1));
        setOffPeakMaxTime(ret.info[0].offpeak.max.ts);
      }

      if ("peak" in ret.info[0]) {
        setPeakMin(numberWithCommas(ret.info[0].peak.min.value,1));
        setPeakMinTime(ret.info[0].peak.min.ts);

        setPeakMax(numberWithCommas(ret.info[0].peak.max.value,1));
        setPeakMaxTime(ret.info[0].peak.max.ts);
      }
    }
  }

  var d = new Date();
  d.setDate(d.getDate());
  var curr_date = d.getDate();
  var curr_month = d.getMonth() + 1;
  var curr_year = d.getFullYear();
  var dateString =
    curr_month + "/" + curr_date + "/" + curr_year + ", 00:00:00 AM UTC+7";
  var timeStamp = Math.floor(new Date(dateString).getTime() / 1000);

  var d = new Date();
  d.setDate(d.getDate() - 13);
  var last_14_curr_date = d.getDate();
  var last_14_curr_month = d.getMonth();
  var last_14_curr_year = d.getFullYear();

  var d = new Date();
  d.setDate(d.getDate() - 6);
  var last_7_curr_date = d.getDate();
  var last_7_curr_month = d.getMonth();
  var last_7_curr_year = d.getFullYear();

  useEffect(() => {
    dailyEnergy(dailyEnergyCB, { ts: { $gte: timeStamp } });
    energyLastHour(energyLastHourCB, {});
    energyLastLastHour(energyLastLastHourCB, {});
    todayConsumptionInfo(todayConsumptionInfoCB, {});
  }, []);

  useInterval(() => {
    dailyEnergy(dailyEnergyCB, { ts: { $gte: timeStamp } });
    energyLastHour(energyLastHourCB, {});
    energyLastLastHour(energyLastLastHourCB, {});
    todayConsumptionInfo(todayConsumptionInfoCB, {});
  }, refreshInterval);

  return (
    <div className="power">
      <div className="main-holder">
        <div className="row top-pane">
          <div className="col-12 col-lg-4 today-info-gp">
            <div className="row itm-gp">
              <div className="col-12 title">Today's Consumption</div>
              <div className="col-6 col-md-4 col-lg-4 itm">
                <div className="label">Office hour</div>
                <div className="value-gp">
                  <span className="value-l">
                    {numberWithCommas(officeHour)}
                  </span>{" "}
                  <span className="unit">kWh</span>
                </div>
              </div>
              <div className="col-6 col-md-4 col-lg-4 itm">
                <div className="label">Non office hour</div>
                <div className="value-gp">
                  <span className="value-l">
                    {numberWithCommas(nonOfficeHour)}
                  </span>{" "}
                  <span className="unit">kWh</span>
                </div>
              </div>
              <div className="col-6 col-md-4 col-lg-4 itm">
                <div className="label">
                  <span className="roboto">30</span> days average
                </div>
                <div className="value-gp">
                  <span className="value-l">{numberWithCommas(avg30Days)}</span>{" "}
                  <span className="unit">kWh</span>
                </div>
              </div>
            </div>
            <div className="row itm-gp">
              <div className="col-6 col-md-3 col-lg-3 itm">
                <div className="label">Curr hour</div>
                <div className="value-gp">
                  <span className="value-l">
                    {numberWithCommas(currentHour)}
                  </span>{" "}
                  <span className="unit">kWh</span>
                </div>
              </div>
              <div className="col-6 col-md-4 col-lg-4 itm">
                <div className="label">Gross Floor Area</div>
                <div className="value-gp">
                  <span className="value-l">
                    {numberWithCommas(grossFloorArea)}
                  </span>{" "}
                  <span className="unit">
                    m<sup>2</sup>
                  </span>
                </div>
	       </div>
               <div className="col-6 col-md-5 col-lg-5 itm">
                 <div className="label">GHG Emission</div>
                   <div className="value-gp">
                     <span className="value-l">
                       {emissionsFactor.toFixed(2)}
                     </span>{" "}
                     <span className="unit">
                       kgCO2e/kWh
                     </span>
		   </div>
                 </div>
              </div>
          </div>
          <div className="col-12 col-lg equip-info-gp">
            <div className="row">
              <div className="col-6 col-md-4 col-lg">
                <div className="box-round">
                  <div className="row">
                    <div className="col-12">
                      <div className="row no-gutters label-gp">
                        <div className="col-auto">
                          <img src="images/top-icon-chiller.png" />
                        </div>
                        <div className="col label">Chiller</div>
                      </div>
                    </div>
                    <div className="col-12 value-gp">
                      <span className="value-l">
                        {numberWithCommas(chillerLastHour)}
                      </span>
                      <span className="unit">kWh</span>
                    </div>
                    <div
                      className={
                        chillerLastHour > chillerLastLastHour
                          ? "col-12 compare-increase"
                          : "col-12 compare-decrease"
                      }
                    >
                      {chillerLastLastHour
                        ? (
                            ((chillerLastHour - chillerLastLastHour) * 100) /
                            chillerLastLastHour
                          ).toFixed(1)
                        : 0}{" "}
                      %
                    </div>
                    <div className="col-12 compare-label">vs. last hour</div>
                    <div className="col-12 unit-statement">
                      ({chillerLastHourpm2} W per m<sup>2</sup>)
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6 col-md-4 col-lg">
                <div className="box-round">
                  <div className="row">
                    <div className="col-12">
                      <div className="row no-gutters label-gp">
                        <div className="col-auto">
                          <img src="images/top-icon-mv.png" />
                        </div>
                        <div className="col label">
                          MV
                          <br />
                          (AHU + MV)
                        </div>
                      </div>
                    </div>
                    <div className="col-12 value-gp">
                      <span className="value-l">
                        {numberWithCommas(MVLastHour)}
                      </span>
                      <span className="unit">kWh</span>
                    </div>
                    <div
                      className={
                        MVLastHour > MVLastLastHour
                          ? "col-12 compare-increase"
                          : "col-12 compare-decrease"
                      }
                    >
                      {MVLastLastHour
                        ? (
                            ((MVLastHour - MVLastLastHour) * 100) /
                            MVLastLastHour
                          ).toFixed(1)
                        : 0}{" "}
                      %
                    </div>
                    <div className="col-12 compare-label">vs. last hour</div>
                    <div className="col-12 unit-statement">
                      ({MVLastHourpm2} W per m<sup>2</sup>)
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6 col-md-4 col-lg">
                <div className="box-round">
                  <div className="row">
                    <div className="col-12">
                      <div className="row no-gutters label-gp">
                        <div className="col-auto">
                          <img src="images/top-icon-lift.png" />
                        </div>
                        <div className="col label">Lift &amp; Escalator</div>
                      </div>
                    </div>
                    <div className="col-12 value-gp">
                      <span className="value-l">
                        {numberWithCommas(liftLastHour)}
                      </span>
                      <span className="unit">kWh</span>
                    </div>
                    <div
                      className={
                        liftLastHour > liftLastLastHour
                          ? "col-12 compare-increase"
                          : "col-12 compare-decrease"
                      }
                    >
                      {liftLastLastHour
                        ? (
                            ((liftLastHour - liftLastLastHour) * 100) /
                            liftLastLastHour
                          ).toFixed(1)
                        : 0}{" "}
                      %
                    </div>
                    <div className="col-12 compare-label">vs. last hour</div>
                    <div className="col-12 unit-statement">
                      ({liftLastHourpm2} W per m<sup>2</sup>)
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6 col-md-4 col-lg">
                <div className="box-round">
                  <div className="row">
                    <div className="col-12">
                      <div className="row no-gutters label-gp">
                        <div className="col-auto">
                          <img src="images/top-icon-p&s.png" />
                        </div>
                        <div className="col label">P&amp;S</div>
                      </div>
                    </div>
                    <div className="col-12 value-gp">
                      <span className="value-l">
                        {numberWithCommas(PSLastHour)}
                      </span>
                      <span className="unit">kWh</span>
                    </div>
                    <div
                      className={
                        PSLastHour > PSLastLastHour
                          ? "col-12 compare-increase"
                          : "col-12 compare-decrease"
                      }
                    >
                      {PSLastLastHour
                        ? (
                            ((PSLastHour - PSLastLastHour) * 100) /
                            PSLastLastHour
                          ).toFixed(1)
                        : 0}{" "}
                      %
                    </div>
                    <div className="col-12 compare-label">vs. last hour</div>
                    <div className="col-12 unit-statement">
                      ({PSLastHourpm2} W per m<sup>2</sup>)
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6 col-md-4 col-lg">
                <div className="box-round">
                  <div className="row">
                    <div className="col-12">
                      <div className="row no-gutters label-gp">
                        <div className="col-auto">
                          <img src="images/top-icon-lighting.png" />
                        </div>
                        <div className="col label">Lighting</div>
                      </div>
                    </div>
                    <div className="col-12 value-gp">
                      <span className="value-l">
                        {numberWithCommas(lightingLastHour)}
                      </span>
                      <span className="unit">kWh</span>
                    </div>
                    <div
                      className={
                        lightingLastHour > lightingLastLastHour
                          ? "col-12 compare-increase"
                          : "col-12 compare-decrease"
                      }
                    >
                      {lightingLastLastHour
                        ? (
                            ((lightingLastHour - lightingLastLastHour) * 100) /
                            lightingLastLastHour
                          ).toFixed(1)
                        : 0}{" "}
                      %
                    </div>
                    <div className="col-12 compare-label">vs. last hour</div>
                    <div className="col-12 unit-statement">
                      ({lightingLastHourpm2} W per m<sup>2</sup>)
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6 col-md-4 col-lg">
                <div className="box-round">
                  <div className="row">
                    <div className="col-12">
                      <div className="row no-gutters label-gp">
                        <div className="col-auto">
                          <img src="images/top-icon-tenant.png" />
                        </div>
                        <div className="col label">Tenant</div>
                      </div>
                    </div>
                    <div className="col-12 value-gp">
                      <span className="value-l">
                        {numberWithCommas(tenantLastHour)}
                      </span>
                      <span className="unit">kWh</span>
                    </div>
                    <div
                      className={
                        tenantLastHour > tenantLastLastHour
                          ? "col-12 compare-increase"
                          : "col-12 compare-decrease"
                      }
                    >
                      {tenantLastLastHour
                        ? (
                            ((tenantLastHour - tenantLastLastHour) * 100) /
                            tenantLastLastHour
                          ).toFixed(1)
                        : 0}{" "}
                      %
                    </div>
                    <div className="col-12 compare-label">vs. last hour</div>
                    <div className="col-12 unit-statement">
                      ({tenantLastHourpm2} W per m<sup>2</sup>)
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row bottom-pane no-gutters">
          <div className="col-12 col-lg-3 power-consumption-gp">
            <div className="box">
              <div className="row">
                {<EnergyGraphLevel />}
                <div className="col-12 legend-gp">
                  <div className="row justify-content-center">
                    <div className="col" />
                    <div className="col-auto itm">
                      <div className="row">
                        <div className="col-auto icon">
                          <img src="images/legend-dash-yellow.png" />
                        </div>
                        <div className="col-auto legent-txt">
                          30 days Average Reference
                        </div>
                      </div>
                    </div>

                    <div className="col" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-3 seven-day-consumption-gp">
            <div className="box">
              <div className="row">
                <div className="col-12 box-title">
                  <span className="roboto">7</span> days electrical consumption
                </div>
                <div className="col-12">
                  <div style={{ width: 520, paddingTop: 80 }}>
                    {<EnergyGraphSegment />}
                  </div>
                </div>
                <div className="col-12 x-title">
                  {last_7_curr_date}{" "}
                  {getSemiMonthFromMonth(0, last_7_curr_month)} - {curr_date}{" "}
                  {getSemiMonthFromMonth(0, curr_month - 1)}
                </div>
                <div className="col-12 legend-gp">
                  <div className="row itm-gp mb-2 target">
                    <div className="col-auto icon">
                      <img src="images/legend-target-line.png" />
                    </div>
                    <div className="col-auto legent-txt">
                      Target Value for electrical Consumption
                    </div>
                  </div>
                  <div className="row itm-gp">
                    <div className="col-auto itm ">
                      <div className="row">
                        <div className="col-auto icon">
                          <img src="images/legend-dot-blue.png" />
                        </div>
                        <div className="col-auto legent-txt">Chiller</div>
                      </div>
                    </div>
                    <div className="col-auto itm ">
                      <div className="row ">
                        <div className="col-auto icon">
                          <img src="images/legend-dot-purple.png" />
                        </div>
                        <div className="col-auto legent-txt">MV</div>
                      </div>
                    </div>
                    <div className="col-auto itm ">
                      <div className="row ">
                        <div className="col-auto icon">
                          <img src="images/legend-dot-yellow.png" />
                        </div>
                        <div className="col-auto legent-txt">
                          Lift &amp; Escalator
                        </div>
                      </div>
                    </div>
                    <div className="col-auto itm ">
                      <div className="row ">
                        <div className="col-auto icon">
                          <img src="images/legend-dot-pink.png" />
                        </div>
                        <div className="col-auto legent-txt">P&amp;S</div>
                      </div>
                    </div>
                    <div className="col-auto itm ">
                      <div className="row ">
                        <div className="col-auto icon">
                          <img src="images/legend-dot-green.png" />
                        </div>
                        <div className="col-auto legent-txt">Lighting</div>
                      </div>
                    </div>
                    <div className="col-auto itm ">
                      <div className="row ">
                        <div className="col-auto icon">
                          <img src="images/legend-dot-red.png" />
                        </div>
                        <div className="col-auto legent-txt">Tenant</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg daily-consumption-gp">
            <div className="box">
              <div className="row">
                <div className="col" />
                <div className="col-12 box-title">
                  Daily electrical consumption
                </div>
                <div className="col-12">
                  <div className="row hightlight-gp">
                    <div className="col-6 col-lg-auto itm">
                      <div className="itm-gp">
                        <div className="label" style={{ fontSize: 15 }}>
                          Non Office Min
                        </div>
                        <div className="value-gp">
                          <span className="value-m">{offPeakMin}</span>
                          <span className="unit">kWh</span>
                        </div>
                        <div className="txt-desc">{offPeakMinTime}</div>
                      </div>
                    </div>
                    <div className="col-6 col-lg-auto itm">
                      <div className="itm-gp">
                        <div className="label" style={{ fontSize: 15 }}>
                          Non Office Max
                        </div>
                        <div className="value-gp">
                          <span className="value-m">{offPeakMax}</span>
                          <span className="unit">kWh</span>
                        </div>
                        <div className="txt-desc">{offPeakMaxTime}</div>
                      </div>
                    </div>
                    <div className="col-6 col-lg-auto itm">
                      <div className="itm-gp">
                        <div className="label" style={{ fontSize: 15 }}>
                          Office Min
                        </div>
                        <div className="value-gp">
                          <span className="value-m">{peakMin}</span>
                          <span className="unit">kWh</span>
                        </div>
                        <div className="txt-desc">{peakMinTime}</div>
                      </div>
                    </div>
                    <div className="col-6 col-lg-auto itm">
                      <div className="itm-gp">
                        <div className="label" style={{ fontSize: 15 }}>
                          Office Max
                        </div>
                        <div className="value-gp">
                          <span className="value-m">{peakMax}</span>
                          <span className="unit">kWh</span>
                        </div>
                        <div className="txt-desc">{peakMaxTime}</div>
                      </div>
                    </div>
                    {/* <div className="col"></div> */}
                  </div>
                </div>
                <div className="col-12">
                  <div style={{ width: 720, paddingTop: 60 }}>
                    {<EnergyGraph />}
                  </div>
                </div>
                <div className="col-12 x-title">
                  {last_14_curr_date}{" "}
                  {getSemiMonthFromMonth(0, last_14_curr_month)} - {curr_date}{" "}
                  {getSemiMonthFromMonth(0, curr_month - 1)}
                </div>
                <div className="col-12 legend-holder legend-gp">
                  <div className="row  justify-content-center">
                    <div className="col-auto col-md blank itm" />
                    <div className="col-auto itm ">
                      <div className="row ">
                        <div className="col-auto icon">
                          <img src="images/legend-dash-blue.png" />
                        </div>
                        <div className="col-auto legent-txt">
                          14 Days Max Refrences
                        </div>
                      </div>
                    </div>
                    <div className="col-auto ">
                      <div className="row itm">
                        <div className="col-auto icon">
                          <img src="images/legend-dash-pink.png" />
                        </div>
                        <div className="col-auto legent-txt">
                          14 Days Min Refrences
                        </div>
                      </div>
                    </div>

                    <div className="col-auto ">
                      <div className="row itm">
                        <div className="col-auto icon">
                          <img src="images/legend-dash-yellow.png" />
                        </div>
                        <div className="col-auto legent-txt">
                          14 Days Average Refrences
                        </div>
                      </div>
                    </div>

                    <div className="col-auto col-md" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12" />
      </div>
    </div>
  );
}

export default Energy;
